import Audit from './admin/audit';
import AdminAuth from './admin/auth';
import Brokers from './admin/brokers';
import Documents from './admin/documents';
import Emails from './admin/emails';
import Flows from './admin/flows';
import AdminFormInitiationPages from './admin/form-initiation-pages';
import Growth from './admin/growth';
import AdminOrgs from './admin/orgs';
import TransactionTemplates from './admin/transaction-templates';
import AdminTransactions from './admin/transactions';
import { create } from './axios';
import codes from './codes';
import Account from './public/account';
import Activities from './public/activities';
import Envelopes from './public/envelopes';
import Forms from './public/forms';
import Integrations from './public/integrations';

const axios = create('/api/');

const adminApi = {
  audit: new Audit(axios),
  auth: new AdminAuth(axios),
  account: new Account(axios),
  brokers: new Brokers(axios),
  transactions: new AdminTransactions(axios),
  transactionTemplates: new TransactionTemplates(axios),
  formInitiationPages: new AdminFormInitiationPages(axios),
  integrations: new Integrations(axios),
  documents: new Documents(axios),
  growth: new Growth(axios),
  emails: new Emails(axios),
  orgs: new AdminOrgs(axios),
  flows: new Flows(axios),
  forms: new Forms(axios),
  activities: new Activities(axios),
  envelopes: new Envelopes(axios),
  ...codes,
} as const;

export default adminApi;
